<script lang="js" setup>
const emit = defineEmits(["select", "nothing"]);
defineProps({
  group: {
    type: Object,
    required: true,
  },
});

const condtion2 = (p) => {
  return isInSavedPayment(p) && isNeedPaymentActivation(p);
};

const activatePayment = (p) => {
  console.log("activatePayment:", p);
  amplitudeTrackBtnClicked("Activate Payment", p.title, "");
  const ui = useUiStore();
  ui.modalPayment = false;
  if (["card", "bcadebit"].includes(p.id)) {
    return navigateTo(`/payment/card`);
  } else if (["ovo"].includes(p.id)) {
    return navigateTo(`/payment/terms/ovo`);
  } else if (["dana"].includes(p.id)) {
    return navigateTo(`/payment/terms/dana`);
  } else if (["shopeepay"].includes(p.id)) {
    return navigateTo(`/payment/terms/shopeepay`);
  } else if (["gopay"].includes(p.id)) {
    return navigateTo(`/payment/gopay`);
  }
};

const detailPayment = (p) => {
  console.log("detailPayment:", p);
  amplitudeTrackBtnClicked("Detail Payment", p.title, "");
  useUiStore().modalPayment = false;
  if (["card", "bcadebit"].includes(p.id)) {
    return navigateTo(`/payment/card`);
  } else if (["ovo"].includes(p.id)) {
    return navigateTo(`/payment/detail/ovo`);
  } else if (["dana"].includes(p.id)) {
    return navigateTo(`/payment/detail/dana`);
  } else if (["shopeepay"].includes(p.id)) {
    return navigateTo(`/payment/detail/shopeepay`);
  }
};

const payment = usePaymentStore();
const shopper = useShopperStore();

const selectedPayment = computed(() => {
  if (payment.tempPayment !== null) {
    return payment.tempPayment;
  } else if (shopper.defPayment && payment.tempPayment === null) {
    return shopper.defPayment;
  } else {
    return {};
  }
});
console.log("selectedPayment:", selectedPayment);
</script>
<template>
  <div v-if="group.is_show === 'true'" id="payGroup" class="flex-row w-full">
    <div class="text-xs font-semibold payGroupTitle leading-5">
      {{ group.title }}
    </div>
    <div v-for="(p, k) in group.children" :key="k" class="payGroupWrap">
      <Summary v-if="p.is_enabled" class="payChild !pr-0">
        <template #leftIcon>
          <input
            v-if="noNeedPaymentActivation(p)"
            :id="`radioPay_${p.id}`"
            type="radio"
            name="paychild"
            :value="isInSavedPayment(p) ? getPaymentTokenId(p) : p.id"
            :checked="selectedPayment?.id === p.id"
            :disabled="selectedPayment?.id === p.id"
            class="flk-radio"
            @click="
              amplitudeTrackBtnClicked('Select Payment Button', p.title, '');
              selectedPayment?.id === p.id
                ? emit('nothing')
                : emit(
                    'select',
                    Object.assign(p, {
                      group_id: group.group_id,
                      token_id: isInSavedPayment(p)
                        ? getPaymentTokenId(p)
                        : p.id,
                    }),
                  );
            "
          />
          <input
            v-if="condtion2(p)"
            type="radio"
            name="paychild"
            :value="isInSavedPayment(p) ? getPaymentTokenId(p) : p.id"
            :checked="selectedPayment?.id === p.id"
            :disabled="selectedPayment?.id === p.id"
            class="flk-radio"
            @click="
              amplitudeTrackBtnClicked('Select Payment Button', p.title, '');
              selectedPayment?.id === p.id
                ? emit('nothing')
                : emit(
                    'select',
                    Object.assign(p, {
                      group_id: group.group_id,
                      token_id: isInSavedPayment(p)
                        ? getPaymentTokenId(p)
                        : p.id,
                    }),
                  );
            "
          />
          <div
            v-if="isNeedPaymentActivation(p) && !isInSavedPayment(p)"
            :id="`${p.id}__${getPaymentTokenId(p)}`"
            class="w-4 h-4 p-2 border border-white"
          ></div>

          <div class="payImg">
            <nuxt-img class="w-8 h-auto" :src="p.image" :alt="p.title" />
          </div>
        </template>
        <template #topSummary>
          <div class="flex items-center justify-start gap-3">
            <div class="font-semibold payTitle">{{ p.title }}</div>
          </div>
        </template>
        <template #bottomSummary>
          <div class="payTitle">{{ p.description }}</div>
          <!-- <p>{{ isInSavedPayment(p) ? getPaymentTokenId(p) : p.id }}</p> -->
        </template>
        <template #rightIcon>
          <button
            v-if="isNeedPaymentActivation(p) && !isInSavedPayment(p)"
            :id="`btnActivate${p.id}`"
            class="rounded-md py-2 px-2.5 bg-[#EBF5FF] text-flk-secondary-blue text-xs leading-2 font-medium"
            @click="activatePayment(p)"
          >
            Lanjutkan
          </button>
          <button
            v-if="
              isNeedPaymentActivation(p) &&
              isInSavedPayment(p) &&
              p.id !== 'gopay'
            "
            :id="`btnPayDetail${p.id}`"
            class="rounded-md py-2 px-2.5 text-xs leading-2 font-medium"
            @click="detailPayment(p)"
          >
            <LazyIconsRightArrow />
          </button>
        </template>
      </Summary>
    </div>
  </div>
</template>
